let navMenuItems = []

function reload() {
  navMenuItems = []
  if (localStorage.getItem('irole') == 0) {

    // reportsSubMenu.push({
    //   url: '/report/dynamic-products-log',
    //   name: 'Dynamic Products Log',
    //   i18n: 'DynamicProductsLog',
    //   slug: 'dynamic-products-log',
    // })
    navMenuItems.push({
      url: '/seller/users/view',
      name: 'Users',
      slug: 'seller-users-view',
      i18n: 'Users',
    })
  }

  const user = JSON.parse(localStorage.getItem('userInfo'))
  if (user) {
// has_assign_product: 0
// has_credit: 0
// has_credit_and_balance: 1
// has_customers: 1
// has_device: 1
// has_direct_recharge: 1
// has_discount_slab: 1
// has_discounts: 0
// has_flixbus: 1
// has_dt_one: 0
// has_inventory: 1
// has_invoice: 1
// has_product: 1
// has_reports: 1
// has_supplier: 1
// has_transaction: 1
    if (localStorage.getItem('enable_web_view') == 1) {
      navMenuItems = []

      if (user.has_product) {
        navMenuItems.push({
          url: '/cashcards',
          name: 'Cash Cards',
          // icon: 'BoxIcon',
          svg: 'cashcards.svg',
          i18n: 'CashCards',
          slug: 'cashcards',
        })
      }
      if (user.has_product) {
        navMenuItems.push({
          url: '/callingcards',
          name: 'Calling Cards',
          // icon: 'BoxIcon',
          svg: 'callingcards.svg',
          i18n: 'CallingCards',
          slug: 'callingcards',
        })
      }
      if (user.has_product) {
        navMenuItems.push({
          url: '/giftcards',
          name: 'Gift Cards',
          // icon: 'BoxIcon',
          svg: 'giftcards.svg',
          i18n: 'GiftCards',
          slug: 'giftcards',
        })
      }
      if (user.has_direct_recharge) {
        navMenuItems.push({
          url: '/direct-recharge',
          name: 'Direct Recharge',
          slug: 'direct-recharge',
          svg: 'dr.svg',
          // icon: 'UserIcon',
          i18n: 'DirectRecharge',
        })
      }
      if (user.has_dt_one) {
        navMenuItems.push({
          url: '/international-topup',
          name: 'International TopUp',
          // icon: 'BoxIcon',
          i18n: 'InternationalTopUp',
          svg: 'ir.svg',
          slug: 'international-topup',
        })
      }
      if (user.has_flixbus) {
        navMenuItems.push({
          url: '/flixbus',
          name: 'FlixBus',
          // icon: 'BoxIcon',
          svg: 'flixbus.svg',
          i18n: 'FlixBus',
          slug: 'flixbus',
        })
      }


      if (user.parent_has_hardware_products) {
        navMenuItems.push({
          url: '/hardware/categories',
          name: 'Hardware',
          // icon: 'BoxIcon',
          i18n: 'Hardware',
          slug: 'hardware',
          isNew: true,
        })
      }


      if (localStorage.getItem('irole') == 0) {
        navMenuItems.push({
          url: '/seller/users/view',
          name: 'Users',
          svg: 'users.svg',
          slug: 'seller-users-view',
          i18n: 'Users',
        })
      }

      let reportsSubMenu = [];

      if (user.has_reports) {
        reportsSubMenu.push({
          url: '/seller/sales',
          name: 'Sales Report',
          i18n: 'SalesReports',
          slug: 'sales-report',
        })
        reportsSubMenu.push({
          url: '/seller/zreport',
          name: 'Z-Report',
          i18n: 'ZReport',
          slug: 'zreport',
        })
        if (user.parent_has_hardware_products) {
          reportsSubMenu.push({
            url: '/report/hardware/sales',
            name: 'Hardware Sales Report',
            // icon: 'BoxIcon',
            i18n: 'HardwareSalesReport',
            slug: 'hardware-sales',
          })
        }
      }
      if (localStorage.getItem('irole') == 0) {

        reportsSubMenu.push({
          url: '/seller/recharge-summary',
          name: 'Recharge Summary Report',
          i18n: 'RechargeSummaryReport',
          slug: 'recharge-summary-report',
        })
        reportsSubMenu.push({
          url: '/seller/ledger',
          name: 'Ledger Report',
          i18n: 'PaymentLedger',
          slug: 'seller-ledger',
        })
        reportsSubMenu.push({
          url: '/report/product-discounts-list',
          name: 'Product Discounts List',
          i18n: 'ProductDiscountsList',
          slug: 'product-discounts-list',
        })
      }
      if (reportsSubMenu.length > 0) {
        navMenuItems.push({
          url: null,
          name: 'Reports',
          i18n: 'Reports',
          svg: 'reports.svg',
          submenu: reportsSubMenu,
        })
      }

      if (user.has_reclamation) {
        navMenuItems.push({
          url: '/seller-reclaim-requests',
          name: 'Reclamations',
          // icon: 'BoxIcon',
          i18n: 'Reclamations',
          svg: 'reclaim.svg',
          slug: 'seller-reclaim-requests',
        })
      }

      if (user.has_invoice) {
        let invoices = [
          {
            url: '/invoices-all',
            name: 'Invoices',
            // icon: 'UserIcon',
            i18n: 'Invoices',
            slug: 'invoices-all',
          },
          {
            url: '/invoices-all-reclamation',
            name: 'Reclamation Invoices',
            // icon: 'UserIcon',
            i18n: 'ReclamationInvoices',
            slug: 'invoices-all-reclamation',
          }
        ]
        navMenuItems.push({
          url: null,
          name: 'Invoices',
          // icon: 'UserIcon',
          svg: 'invoices.svg',
          i18n: 'Invoices',
          submenu: invoices,
        })
      }
    }
  }

}

reload();

export { navMenuItems, reload }


