<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.organisation_name">

<!--    <div class="text-right leading-tight hidden sm:block profile-text"-->
<!--         style="max-width: 160px;"-->
<!--         :style="[-->
<!--           {-->
<!--             'min-width: 100px': activeUserInfo.organisation_name.length > 12-->
<!--           }-->
<!--         ]"-->
<!--    >-->
<!--      <p class="font-semibold fit-text profile-text">{{ activeUserInfo.organisation_name }}</p>-->
<!--      <p><small>{{ activeUserInfo.customer_id }}</small></p>-->
<!--    </div>-->

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <vs-avatar class="bg-profile-avatar" color="warning" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

<!--          <li-->
<!--            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"-->
<!--            @click="$router.push('/pages/profile').catch(() => {})">-->
<!--            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />-->
<!--            <span class="ml-2">Profile</span>-->
<!--          </li>-->

<!--          <li-->
<!--            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"-->
<!--            @click="$router.push('/apps/email').catch(() => {})">-->
<!--            <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />-->
<!--            <span class="ml-2">Inbox</span>-->
<!--          </li>-->

<!--          <li-->
<!--            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"-->
<!--            @click="$router.push('/apps/todo').catch(() => {})">-->
<!--            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />-->
<!--            <span class="ml-2">Tasks</span>-->
<!--          </li>-->

<!--          <li-->
<!--            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"-->
<!--            @click="$router.push('/apps/chat').catch(() => {})">-->
<!--            <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />-->
<!--            <span class="ml-2">Chat</span>-->
<!--          </li>-->

<!--          v-if="activeUserInfo.account_role >= 2"-->
<!--          <li v-if="activeUserInfo.internal_role == 0"-->
<!--            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"-->
<!--            @click="$router.push('/seller/users/view').catch(() => {})">-->
<!--            <feather-icon icon="InfoIcon" svgClasses="w-4 h-4" />-->
<!--            <span class="ml-2">{{ $t('Users') }}</span>-->
<!--          </li>-->
<!--          <li v-if="activeUserInfo.has_invoice == 1"-->
<!--            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"-->
<!--            @click="$router.push('/invoices-all').catch(() => {})">-->
<!--            <feather-icon icon="InfoIcon" svgClasses="w-4 h-4" />-->
<!--            <span class="ml-2">{{ $t('Invoices') }}</span>-->
<!--          </li>-->
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/settings').catch(() => {})">
            <feather-icon icon="InfoIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{ $t('Settings') }}</span>
          </li>

<!--          v-if="activeUserInfo.account_role >= 2"-->
          <vs-divider
            class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{ $t('Logout') }}</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    getRole(role) {
      switch (role) {
      case 0:
        return 'Super Admin'
      case 1:
        return 'Reseller'
      case 2:
        return 'Seller'
      case 3:
        return 'Customer'
      default:
        return ''
      }
    },
    logout() {
      // TODO: UnComment for Hard Logout
      // this.$store.dispatch('auth/logoutUser')

      // TODO: Support
      // window.fcWidget.destroy();
      localStorage.removeItem('accessToken')
      localStorage.clear()
      this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'horizontal')
      this.$store.dispatch('updateTheme', 'light')
      this.$vs.theme({ primary: '#00a1f1' })
      this.$router.push('/login').then(() => {
        window.location.reload()
      }).catch(() => {})
    },
    fit() {
      setTimeout(() => {
        let e = document.getElementsByClassName('fit-text')
        fitText(e);
      }, 1000)
    }
  },
  mounted() {
    this.fit()
    setTimeout(this.fit, 4000)
  }
}
</script>
<style>
.the-navbar__user-meta > .leading-tight > p {
  /*display: inline-block;*/
}
</style>
