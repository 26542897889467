let navMenuItems = []

function reload() {
  navMenuItems = []
  const user = JSON.parse(localStorage.getItem('userInfo'))
  if (user) {
// has_assign_product: 0
// has_credit: 0
// has_credit_and_balance: 1
// has_customers: 1
// has_device: 1
// has_discount_slab: 1
// has_discounts: 0
// has_inventory: 1
// has_invoice: 1
// has_product: 1
// has_reports: 1
// has_supplier: 1
// has_transaction: 1

    // if (user.has_product) {
    //   navMenuItems.push({
    //     url: '/products/view',
    //     name: 'Products',
    //     slug: 'products-view',
    //     // icon: 'BoxIcon',
    //     i18n: 'Products',
    //   })
    // }
    // if (user.has_supplier) {
    //   navMenuItems.push({
    //     url: '/suppliers/view',
    //     name: 'Suppliers',
    //     slug: 'suppliers-view',
    //     // icon: 'ShoppingCartIcon',
    //     i18n: 'Suppliers',
    //   })
    // }

    /// Inventory Menu
    let inventorySubmenu = []

    if (user.has_inventory) {
      inventorySubmenu.push({
        url: '/inventory/view',
        name: 'Inventory',
        // icon: 'DatabaseIcon',
        slug: 'inventory-view',
        i18n: 'Inventory',
      })
    }
    if (user.has_gift_card) {
      inventorySubmenu.push({
        url: '/admin/giftcards/',
        name: 'GiftCards',
        // icon: 'DatabaseIcon',
        slug: 'admin-giftcards',
        i18n: 'GiftCards',
      })
    }
    if (user.has_discount_slab) {
      inventorySubmenu.push({
        url: '/discount-slabs',
        name: 'Discount Slabs',
        slug: 'discount-slabs',
        // icon: 'UserIcon',
        i18n: 'DiscountSlabs',
      })
    }
    if (inventorySubmenu.length > 0) {
      const d = {
        url: '/inventory/view',
        name: 'Inventory',
        i18n: 'Inventory',
        submenu: inventorySubmenu,
      }
      navMenuItems.push(d)
    }
    /// Inventory Menu

/// Customers
    let customerSubMenu = []

    if (user.has_customers || user.has_customers_read) {
      customerSubMenu.push({
        url: '/customers/view',
        name: 'Customers',
        slug: 'customer-view',
        // icon: 'UserIcon',
        i18n: 'Customers',
      })
      customerSubMenu.push({
        url: '/stopped-customers/view',
        name: 'Stopped Customers',
        slug: 'stopped-customer-view',
        // icon: 'UserIcon',
        i18n: 'StoppedCustomers',
      })
    }
    if (user.has_customers) {
      customerSubMenu.push({
        url: '/customers/registrations',
        name: 'Registrations',
        slug: 'customer-registrations',
        // icon: 'UserIcon',
        i18n: 'Registrations',
      })
    }

    if (user.has_auto_credit) {
      customerSubMenu.push({
        url: '/customer/credit/auto',
        name: 'Auto Credit',
        // icon: 'DatabaseIcon',
        slug: 'customer-credit-auo',
        i18n: 'AutoCredit',
      })
    }

    if (user.has_assign_product) {
      customerSubMenu.push({
        url: '/customers/pad',
        name: 'Assignments and Discount',
        // icon: 'DatabaseIcon',
        slug: 'customers-pad',
        i18n: 'AssignmentsAndDiscount',
      })
    }
    if (customerSubMenu.length > 0) {
      navMenuItems.push({
        url: null,
        name: 'Customer',
        slug: 'customer-view',
        // icon: 'UserIcon',
        i18n: 'Customers',
        submenu: customerSubMenu,
      })
    }
    /// Customers


    // TopUp
    if (user.has_credit_and_balance) {
      const topUpSubMenu = [
        {
          url: '/customer/credit/add',
          name: 'TopUp',
          i18n: 'TopUp',
          slug: 'credit-add',
        },
        {
          url: '/customer/credit/history',
          name: 'TopUp History',
          i18n: 'TopUpHistory',
          slug: 'credit-history',
        }
      ]

      if (user.internal_role == 0) {
        topUpSubMenu.push({
          url: '/customer/credit/multi',
          name: 'Multi TopUp',
          i18n: 'MultiTopUp',
          slug: 'credit-add-multi',
        })
      }
      navMenuItems.push({
        url: '/customer/credit/add',
        name: 'Customer Management',
        slug: 'credit-add',
        // icon: 'UserIcon',
        i18n: 'TopUp',
        submenu: topUpSubMenu,
      })
    }

    if (localStorage.getItem('irole') == 0) {
      navMenuItems.push({
        url: '/users/view',
        name: 'Staff',
        slug: 'users-view',
        i18n: 'Staff',
      })
    }

    let reportsSubMenu = []

    if (user.has_reports) {
      reportsSubMenu = [
        {
          url: '/reseller-reports-sales',
          name: 'Sales Report',
          // icon: 'UserIcon',
          i18n: 'SalesReports',
          slug: 'reports-sales',
        },
      ]

      reportsSubMenu.push({
        url: '/reports-sales-new',
        name: 'Sales Report New',
        // icon: 'UserIcon',
        i18n: 'SalesReportsNew',
        slug: 'reports-sales-new',
      })
      reportsSubMenu.push({
        url: '/reports-sales-comparison',
        name: 'Sales Comparison',
        // icon: 'UserIcon',
        i18n: 'SalesComparison',
        slug: 'reports-sales-comparison',
      })

      // reportsSubMenu.push({
      //   url: '/report-sales-summary',
      //   name: 'Sales Summary',
      //   // icon: 'UserIcon',
      //   i18n: 'SalesSummary',
      //   slug: 'report-sales-summary',
      // })
    }
    if (localStorage.getItem('irole') == 0) {

      reportsSubMenu.push({
        url: '/report/recharge-summary',
        name: 'Recharge Summary Report',
        i18n: 'RechargeSummaryReport',
        slug: 'recharge-summary-report',
      })

      reportsSubMenu.push({
        url: '/admin/ledger',
        name: 'Ledger Report',
        i18n: 'PaymentLedger',
        slug: 'admin-ledger',
      })
      reportsSubMenu.push({
        url: '/admin/customer-ledger',
        name: 'Customer Ledger Report',
        i18n: 'CustomerPaymentLedger',
        slug: 'admin-customer-ledger',
      })
      reportsSubMenu.push({
        url: '/report/product-discounts-list',
        name: 'Product Discounts List',
        i18n: 'ProductDiscountsList',
        slug: 'product-discounts-list',
      })
      reportsSubMenu.push({
        url: '/reports-stock',
        name: 'Stock Report',
        // icon: 'UserIcon',
        i18n: 'StockReport',
        slug: 'reports-stock',
      })
    }
    if (user.has_hardware_products) {
      reportsSubMenu.push({
        url: '/report/hardware/sales',
        name: 'Hardware Sales Report',
        // icon: 'BoxIcon',
        i18n: 'HardwareSalesReport',
        slug: 'hardware-sales',
      })
    }
    if (reportsSubMenu.length > 0) {
      navMenuItems.push({
        url: null,
        name: 'Reports',
        // icon: 'UserIcon',
        i18n: 'Reports',
        submenu: reportsSubMenu,
      })
    }
    if ((window.location.host.indexOf("e-aufladen.") != -1 || window.location.host.indexOf("sandbox-api.pjtelesoftgmbh.") != -1) && user.internal_role == 0) {
      navMenuItems.push({
        url: null,
        name: 'Banking',
        // icon: 'UserIcon',
        i18n: 'Banking',
        submenu: [
          {
            url: '/banking-upload',
            name: 'Import CSV',
            // icon: 'UserIcon',
            i18n: 'ImportCSV',
            slug: 'banking-upload',
          },
          {
            url: '/banking-list',
            name: 'Bank Records',
            // icon: 'UserIcon',
            i18n: 'BankRecords',
            slug: 'banking-list',
          },
          {
            url: '/banking-customers',
            name: 'Bank Customers Mapped',
            // icon: 'UserIcon',
            i18n: 'MappedBankCustomers',
            slug: 'banking-customers',
          },
          {
            url: '/banking-deleted',
            name: 'Deleted Records',
            // icon: 'UserIcon',
            i18n: 'DeletedRecords',
            slug: 'banking-customers',
          }
        ],
      })
    }

    if (user.has_device) {

      let deviceSubMenu = [{
        url: '/user/devices',
        name: 'Devices',
        i18n: 'Devices',
        slug: 'user-devices',
      },
        {
          url: '/assign/devices/',
          name: 'Assign Devices',
          i18n: 'AssignDevices',
          slug: 'assign-devices',
        }]
      navMenuItems.push({
        url: null,
        name: 'Devices',
        i18n: 'Devices',
        slug: 'devices',
        submenu: deviceSubMenu,
      })
    }


    if (user.internal_role == 0) {
      if (user.has_hardware_products) {
        navMenuItems.push({
          url: '/hardware',
          name: 'Hardware',
          // icon: 'BoxIcon',
          i18n: 'Hardware',
          slug: 'hardware',
        })
      }
    }


    if (user.has_login_requests) {
      navMenuItems.push({
        url: '/login-requests',
        name: 'Login Requests',
        // icon: 'UserIcon',
        i18n: 'LoginRequests',
        slug: 'login-requests',
      })
    }

    if (user.has_reclamation) {
      navMenuItems.push({
        url: '/reseller-reclaim-requests',
        name: 'Reclamations',
        // icon: 'BoxIcon',
        i18n: 'Reclamations',
        slug: 'reseller-reclaim-requests',
      })
    }

    let invoices = [
      {
        url: '/invoices-all',
        name: 'Generated Invoices',
        // icon: 'UserIcon',
        i18n: 'GeneratedInvoices',
        slug: 'invoices-all',
      },
      {
        url: '/invoices-all-reclamation',
        name: 'Generated Reclamation Invoices',
        // icon: 'UserIcon',
        i18n: 'GeneratedReclamationInvoices',
        slug: 'invoices-all-reclamation',
      }
    ]
    if (user.internal_role == 0) {
      invoices.push({
        url: '/my-invoices-all',
        name: 'My Invoices',
        // icon: 'UserIcon',
        i18n: 'MyInvoices',
        slug: 'my-invoices-all',
      })
      invoices.push({
        url: '/my-reclamation-invoices-all',
        name: 'My Reclamation Invoices',
        // icon: 'UserIcon',
        i18n: 'MyReclamationInvoices',
        slug: 'my-reclamation-invoices-all',
      })
    }
    if (user.has_invoice) {
      navMenuItems.push({
        url: null,
        name: 'Invoices',
        // icon: 'UserIcon',
        i18n: 'Invoices',
        submenu: invoices,
      })
    }

  }
}

reload();

export {navMenuItems, reload}

